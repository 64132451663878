@import "../../../common/scss/structure.scss";
.wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: var(--backgroundColor);
    padding: 10px 10px;
    margin:  10px 0; 
    border-radius: $borderRadius;
}
@media (max-width: 1024px) {  
    .wrapper {
        margin:  5px 0; 
    }
   
  }
.titleOfBlock{
    color:var(--textSecondaryColor);
    text-align: center;
}