@import '../../../common/scss/structure.scss';
.createButton{
 background: var(--secondaryColor);
 padding: 5px 10px;
 border-radius: $borderRadius;
 color:var( --textColor);
 text-align: center;
 font-size: 20px;
 box-shadow: $boxShadow;
}
@media (max-width: 1024px) {  
    .createButton{  
        font-size: 12px;  
       }
    
    }