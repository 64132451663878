@import "../common/scss/colors.scss";
@import "../common/scss/structure.scss";
.calendarItem{
    min-height: 100px;
    cursor: pointer;
    border: 2px #dfdfdf30 solid;
    position: relative;
}
.calendarItemSelected {
    border: 2px solid #007bff;
    background-color: rgba(0, 123, 255, 0.1);
    transition: background-color 0.3s ease;
  }
.calendarItem>.actions{ 
    position: absolute; 
    display: flex; 
    align-items: center;
    top: 0px;
    color:var(--primaryColor);
    padding: 10px 20px;
    right: 0;
}
.dayItem{
     position: absolute;
     top: 0; 
     left: 15px;
    
}
.wrapperTotal{
    color: var(--textColor);
    display: flex;
    background: var(--backgroundColor);
    padding: 10px;
    border-radius: 5px;
    margin-top: 40px;
}
@media (max-width: 1048px) {
    .wrapperTotal{ 
        padding: 5px; 
    } 
}
.infoWrapper{
    padding: 2px 5px;
    border-radius: 90px;
    color:var( --textColor);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    cursor: pointer;
    background:var(--primaryColor);
}
.dayItemActive{ 
    color:var(--primaryColor);
}
.dayItemInActive{
    color:var(--secondaryColor);
}
.calendarItemActive{
    background: var(--backgroundDarkerColor);
}
.calendarItemInActive{
    background: var(--backgroundColor);
}
.calendarItem:hover>.actions{
    display: flex;
    align-items: center;
}
.calendarItem>.actions>*{
    margin: 0 5px;
}
.calendarItem{
    top: 10px;
    .scheduledTrainingPlanWrapper{ 
        .scheduledTrainingPlan{
            width: 100%;
            background: orange;
            padding: 10px;
            margin: 2px;
        }
    } 
}