@import "../../../common/scss/colors.scss";
@import "../../../common/scss/structure.scss";
.dropdown {
    @apply absolute top-[42px] right-0 left-0 bg-white border overflow-y-scroll shadow-lg
} 
 


.foodDescription{
    color: black;
    //background: rgb(111 ; 20 ; 26);
     background: var(--backgroundColor);
    color: var( --textColor) !important; 
    padding: 10px;
    border-radius: 5px;
    margin-top: 15px;
}
.searchIcon{
    position:  absolute  ;
    margin-top:  10px  ;
    left:  20px  ;
    color:  var(--secondaryColor)  ;
}
.cancelBtn{
    position: absolute;
    right: 10px;
    height: 100%;
    padding: 5px 10px;
    background: var(--secondaryColor);
    color: white;
    transition: 2s opacity ;
}
@media (max-width: 1024px) {  
    .barCodeButton{
        padding: 5px 2px 2px 5px;
        margin-top: 10px !important;
        margin-bottom: 10px;
    }
    .searchIcon{
        margin-top: 5px;
    }
    .cancelBtn{
        height: 23px;
        padding: 2px 5px;
    }
}
 
 