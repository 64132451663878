@import "../../../../../src/components/common/scss/colors.scss";
@import "../../../../../src/components/common/scss/structure.scss";
.startButtonWrapper{
    background:var(--backgroundColor);
    color: var(--primaryColor);
    padding: 20px 20px;
    border-radius: $borderRadius;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 30px;
}
.trainingInfoWrapper{
    background:var(--backgroundColor);
    color: var(--primaryColor);
    padding: 20px 20px;
    border-radius: $borderRadius;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 30px;
    width: 80%;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 600px;
    min-width: 600px;
}