@import "../../common/scss/colors.scss";
@import "../../common/scss/structure.scss";
.carouselContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 150px; /* Adjust based on design */
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
  }
  
  .slide {
    font-size: 1.2rem;
    text-align: center; 
    opacity: 0.6; 
    color: var(--secondaryColor); 
    cursor: pointer;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: $borderRadius;
    border: 2px var(--secondaryColor) solid;
    padding: 5px 20px;
}

.active {
    font-size: 1.5rem;
    opacity: 1; 
    color:var(--primaryColor); 
  }
  
  .controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .controlBtn {
    background-color: #ddd;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 1.5rem;
    transition: background-color 0.3s;
  }
  
  .controlBtn:hover {
    background-color: #bbb;
  }
  