input.auth:focus{
    outline: none;
  }
  input.error{
  outline: #F05050;
  border: 2px #F05050 solid;
  }
 
  label.error{
    color:  #F05050  ;
  }
  .error-message{
    color:#F05050;
  }