.eyePasswordBtn{
    right: 0;
    width: 30px !important;
    font-size: 24px;
    color: white;
    margin-left: auto;
    margin-right: 20px;
    border: none;
    background: none;
    cursor: pointer;
    height: 100%; 
}
.eyePasswordBtnWrapper{
    position: absolute; 
    display: flex; 
    right: 0; 
    margin-top: 20px;
}
@media (max-width: 1048px) {
    .eyePasswordBtn{
        margin-right: 0;
    }
    .eyePasswordBtnWrapper{
        margin-top: 0px;
    }
  }
 