// var(--primaryColor)Alt:  #403F4C  ;
// var(--primaryColor): #255950;
$secondaryColor: #3B0D11;
// $backgroundColor:#2B2118;
$backgroundColor:#222222;
// $backgroundColor:#1B2432; 

$warningColor:#DF2935;

$bloodyPrimaryColor:#DF2935;
$bloodyColorDarker:rgb(111 20 26);
$bloodyBlackLighterColor:#1b1717;
$bloodyBlackColor:#2b2626;
$bloodyPrimaryTextColor:white;
$bloodySecondaryTextColor:#7C8480;

$lightPrimaryColor: #ffffff;
$lightSecondaryColor: #f0f0f0;
$lightTextColor: #333;
$lightBackgroundColor: #fafafa;
$lightDarkerColor: #e0e0e0;
$lightLighterColor: #ffffff;

$darkPrimaryColor: #1e1e1e;
$darkSecondaryColor: #2b2b2b;
$darkTextColor: #f5f5f5;
$darkBackgroundColor: #121212;
$darkDarkerColor: #0d0d0d;
$darkLighterColor: #2e2e2e;

$forestPrimaryColor: #2e8b57;
$forestSecondaryColor: #3c9e70;
$forestTextColor: #ffffff;
$forestBackgroundColor: #1c4031;
$forestDarkerColor: #205239;
$forestLighterColor: #4aa273;

$royalPrimaryColor: #6a5acd;
$royalSecondaryColor: #836fff;
$royalTextColor: #ffffff;
$royalBackgroundColor: #3a2f5d;
$royalDarkerColor: #2e2348;
$royalLighterColor: #8e7de8;

$solarPrimaryColor: #fcbf49;
$solarSecondaryColor: #f77f00;
$solarTextColor: #222222;
$solarBackgroundColor: #fff5e1;
$solarDarkerColor: #d99800;
$solarLighterColor: #ffe6a2;

$oceanPrimaryColor: #0077b6;
$oceanSecondaryColor: #0096c7;
$oceanTextColor: #e0f7fa;
$oceanBackgroundColor: #caf0f8;
$oceanDarkerColor: #005f8a;
$oceanLighterColor: #90e0ef;


:root {
    --primaryColor: #{$bloodyPrimaryColor};
    --secondaryColor: #{$bloodyColorDarker};
    --backgroundDarkerColor: #{$bloodyBlackLighterColor};
    --backgroundColor: #{$bloodyBlackColor};
     --textColor: #{$bloodyPrimaryTextColor};
    --textSecondaryColor: #{$bloodySecondaryTextColor};
    --warningColor:#{$warningColor};
}

[data-theme='standart'] {
    --primaryColor: #{$bloodyPrimaryColor};
    --secondaryColor: #{$bloodyColorDarker};
    --backgroundDarkerColor: #{$bloodyBlackLighterColor};
    --backgroundColor: #{$bloodyBlackColor};
    --textColor: #{$bloodyPrimaryTextColor};
    --textSecondaryColor: #{$bloodySecondaryTextColor};
    --warningColor:#{$warningColor};
}

[data-theme='bloody'] {
    --primaryColor: #{$bloodyPrimaryColor};
    --secondaryColor: #{$bloodyColorDarker};
    --backgroundDarkerColor: #{$bloodyBlackLighterColor};
    --backgroundColor: #{$bloodyBlackColor};
    --textColor: #{$bloodyPrimaryTextColor};
    --textSecondaryColor: #{$bloodySecondaryTextColor};
    --warningColor:#{$warningColor};
}
[data-theme='light'] {
    --primaryColor: #{$lightPrimaryColor};
    --secondaryColor: #{$lightSecondaryColor};
    --textColor: #{$lightTextColor};
    --backgroundColor: #{$lightBackgroundColor};
    --backgroundDarkerColor: #{$lightDarkerColor};
    --textSecondaryColor: #{$lightLighterColor};
    --warningColor:#{$warningColor};
}

[data-theme='dark'] {
    --primaryColor: #{$darkPrimaryColor};
    --secondaryColor: #{$darkSecondaryColor};
    --textColor: #{$darkTextColor};
    --backgroundColor: #{$darkBackgroundColor};
    --backgroundDarkerColor: #{$darkDarkerColor};
    --textSecondaryColor: #{$darkLighterColor};
    --warningColor:#{$warningColor};
}

[data-theme='forest'] {
    --primaryColor: #{$forestPrimaryColor};
    --secondaryColor: #{$forestSecondaryColor};
    --textColor: #{$forestTextColor};
    --backgroundColor: #{$forestBackgroundColor};
    --backgroundDarkerColor: #{$forestDarkerColor};
    --textSecondaryColor: #{$forestLighterColor};
    --warningColor:#{$warningColor};
}

[data-theme='royal'] {
    --primaryColor: #{$royalPrimaryColor};
    --secondaryColor: #{$royalSecondaryColor};
    --textColor: #{$royalTextColor};
    --backgroundColor: #{$royalBackgroundColor};
    --backgroundDarkerColor: #{$royalDarkerColor};
    --textSecondaryColor: #{$royalLighterColor};
    --warningColor:#{$warningColor};
}

[data-theme='solar'] {
    --primaryColor: #{$solarPrimaryColor};
    --secondaryColor: #{$solarSecondaryColor};
    --textColor: #{$solarTextColor};
    --backgroundColor: #{$solarBackgroundColor};
    --backgroundDarkerColor: #{$solarDarkerColor};
    --textSecondaryColor: #{$solarLighterColor};
    --warningColor:#{$warningColor};
}

[data-theme='ocean'] {
    --primaryColor: #{$oceanPrimaryColor};
    --secondaryColor: #{$oceanSecondaryColor};
    --textColor: #{$oceanTextColor};
    --backgroundColor: #{$oceanBackgroundColor};
    --backgroundDarkerColor: #{$oceanDarkerColor};
    --textSecondaryColor: #{$oceanLighterColor};
    --warningColor:#{$warningColor};
}