@import "../../../common/scss/colors.scss";
@import "../../../common/scss/structure.scss";
.plusSymbol{
    position: absolute;
     bottom: 0;
      margin-left: -5px; 
      margin-bottom: -5px;
      color:var(--secondaryColor);
    }
    .wrapperSymbol{
        position: relative;
        display: flex; 
        cursor: pointer;
        color:var(--primaryColor)
}
 