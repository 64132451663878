.barCodeButton{
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondaryColor);
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    margin-top: 20px !important;
    margin-bottom: 20px;
    padding: 15px 5px;
}
@media (max-width: 1024px) {  
    .barCodeButton{
        padding: 5px 2px 2px 5px;
        margin-top: 10px !important;
        margin-bottom: 10px;
    }
    
    }