@import "../../../common/scss/colors.scss";
@import "../../../common/scss/structure.scss";
.foodName{


}

.foodDescription{
    position: absolute;
    display: none;
    padding: 10px 20px;
    border: $borderRadius;
    /* border: 1px #6f141a solid !important; */
    font-size: 1rem;
    border: 0;
}
.foodName:hover>.foodDescription{
    display: block;
}
.foodUpperIndex{
 position: absolute;
 font-size: 12px;;
 top: 0;   
}
.foodMacronutrientsValue{
    position: relative;
    z-index: 233;
}
.foodWrapper{
    text-align: left; 
    background: var(--backgroundDarkerColor);
    color: var( --textColor);
    border-radius: $borderRadius;
    padding: 10px 20px;
    margin: 15px;
}
.foodMacronutrientsWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    align-content: space-between; 
}
@media (max-width: 1024px) {
    .foodWrapper{
        padding: 2px 5px;
    }
}

