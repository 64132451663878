@import "../../../common/scss/colors.scss";
@import "../../../common/scss/structure.scss";
.playButton{
    cursor: pointer;
  height: 100px;
  color:var(--primaryColor)
}
.playButtonWrapper{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}
 .textInfo{
    margin-bottom: 40px;
    color:var(--primaryColor)
}