@import  "../../common/scss/structure.scss";
.searchedLi{
    background: var(--backgroundDarkerColor);
    position: relative;
}
.listWrapper{
       background: var(--backgroundColor);
    padding: 5px 10px;
    border-radius: $borderRadius;
    color:var( --textColor);
    box-shadow: inset 2px 2px 2px black; 
}