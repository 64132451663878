
@import "../../common/scss/colors.scss";
@import "../../common/scss/structure.scss";
.carouselContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.carouselInner {
  display: flex;
  transition: transform 0.7s ease-in-out;
  width: 100%;
  overflow: hidden;
}

.carouselItemWrapper {
  flex: 1;
  width: 100%;
  opacity: 0;  // Hide initially
  visibility: hidden;  // Hide off-screen elements
  position: absolute;
  display: flex;
  justify-content: space-around;
  transition: opacity 0.7s ease, visibility 0s 0.7s;  // Smooth transition
}

.carouselItemWrapper.active {
  opacity: 1;
  visibility: visible;
  position: relative;
}

.carouselIndicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.indicator {
  padding: 5px;
  margin: 0 2px;
  cursor: pointer;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: var(--secondaryColor);
}

.indicator.active {
  background-color: var(--primaryColor);
}

.carouselCounter {
  text-align: center;
  margin-top: 10px;
  color:  var(--primaryColor);
}