@import "../../../../common/scss/structure.scss";
@import "../../../../common/scss/colors.scss";
.mealInfo{ 
    position: relative; 
    border-radius: 5px 5px 0 0;
    color: var( --textColor);
    flex-direction: column;
    justify-content: center;
    display: flex; 
    align-items: center;
    vertical-align: middle;
    background: var(--backgroundDarkerColor);
    width: 100%;
    padding: 15px 10px;
    border-radius: $borderRadius;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: center;
    box-shadow: $boxShadow;
    width: 400px;
}
.mealItemSchevron{
    position: absolute;
    right: 25px;
}

.mealItemButtonWrapper{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.mealFoodInfoWrapper{
    background: var(--backgroundDarkerColor);
    box-shadow: inset $boxShadow;
    padding: 5px 20px;
}
.mealInfoIcon{
    background: var(--secondaryColor);
    padding:  15px;
    border-radius: 90px;
    color: var( --textColor);
}
.mealFoodInfo{ 
    background: var(--backgroundColor);
}
.mealInfoDetailInside{
    display: flex; 
    flex-direction: column;
    margin-left: 5px;
    margin-right: 5px;
    // color:var(--primaryColor);
    color:var(--textSecondaryColor);

}
.mealInfo:hover>.infoPanel{
opacity: 1;
transition: 0.5s;

}
.infoPanel{
    display: flex; 
    top: 15px;
    opacity: 0; 
    z-index: 2;
    padding: 0 20px; 
    transition: 0.5s;
}
@media (max-width: 1024px) {  
    .mealInfo{ 
        padding: 5px 3px; 
        margin-top: 5px;
        margin-bottom: 5px; 
    }
    .mealItemSchevron{right:5px;}
    .mealFoodInfoWrapper{
        padding: 2px 5px;
    }
    .mealItemButtonWrapper{
        margin-top: 5px;
        margin-bottom: 5px;
    }.mealInfoIcon{
    padding: 6px;
    }
}