@import "/src/components/common/scss/colors.scss";
@import "/src/components/common/scss/structure.scss";
.container { 
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .line {
    border-left: 2px solid black;
    position: relative;
    padding-left: 20px;
  }
  
  .dateContainer {
    margin-bottom: 30px;
    position: relative;
  }
  
  .horizontalBranch {
    border-bottom: 2px solid black;
    width: 100px;
    position: absolute;
    top: 10px;
    left: -120px;
  }
  
  .date {
    position: relative;
    left: -120px;
    top: -15px;
    color: var(--primaryColor);
    font-weight: bold;
  }
  
  .mealList {
    background: var(--backgroundDarkerColor);
    color: var( --textColor);
    padding: 10px 20px;
    box-shadow: inset 2px 2px 2px black;
  }
  
  .mealItem {
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .loading {
    text-align: center;
    margin-top: 20px;
  }
  .deficitWrapper{
    background: #1b1717;
    color: var( --textColor);
    padding: 10px 20px;
    box-shadow: inset 2px 2px 2px black;
    width: fit-content;
    margin-left: auto;
    position: absolute;
    right: 20px;
  }