@import "/src/components/common/scss/colors.scss";
@import "/src/components/common/scss/structure.scss";
.btn{
    background: transparent;
    padding: 10px 20px;
    border:2px var(--primaryColor) solid;
    border-radius:$borderRadius;
    color: var(--primaryColor);
    height: 70px;
    cursor: pointer;
}