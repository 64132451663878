.calendarWrapper{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 1048px) {
.calendarWrapper{ 
    grid-template-columns: repeat(3, 1fr); 
    width: 100%; 
}
.dayOfWeekItem{
    display: none;
}
}