.dropdownItems{
    max-height: 200px;
    overflow-y: auto;
}

.weightModifierLi{
    background: var(--backgroundColor);
    position: relative;
    text-align: center;
}
 
.foodWrapper{
    padding: 10px;
    overflow-y: hidden;
    background: var(--backgroundDarkerColor);
}
.plateIcon{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    border: 2px var(--secondaryColor) solid;
    background-color: var(--secondaryColor);
    padding:   10px;
    border-radius: 5px;
}
.plateIcon:hover{
    background-color: var(--primaryColor);
    transition: 0.2s background-color;
}
@media (max-width: 1024px) {
    .plateIcon{
        padding: 0 2px;
    }
}