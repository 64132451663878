$body-content-modal-color: black;
$body-content-modal-background-color: var( --textColor);
$grey-outline: pink;
$body-bar-modal-color: yellow;
$body-bar-modal-background-color: blue;
.modalBox{
    min-width: 60%;
    max-height: 90vh;
    overflow-y: auto;
    background: var(--backgroundDarkerColor);
}
.closeIcon{
  font-size: 25px;
  color:var(--warningColor);
  fill: var(--warningColor);
  opacity: 0.7;
}
.closeIcon:hover{
  opacity: 1;
}
.buttonWrapper{
  display: flex;
  padding-top: 10px; 
  flex-direction: row-reverse;
  margin-right: 10px;
}
.modalBackground {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  place-items: center;
  z-index: 10;

  .modalWindow {
    min-height: 650px;
    width: 760px;
    position: relative;
    color: $body-content-modal-color;
    background-color: $body-content-modal-background-color;
    user-select: none;

    .modalBlock {
      font-weight: 300;
      font-size: 20px;
      color: #8b8b8b;
      margin-top: 25px;
      margin-bottom: 25px;
      span {
        margin-right: 20px;
        color: #282828;
      }
      .textAreaLabel {
        font-weight: 300;
        font-size: 14px;
      }

      .modalPatientInfo {
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;
        margin-bottom: 10px;
        font-size: 18px;

        & > div {
          span {
            text-decoration: underline;
            margin-right: 5px;
          }
        }
      }
      .monitorTitle {
        margin-bottom: 10px;
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }

    .buttonWrapper {
      position: absolute;
      bottom: 0;
      display: flex;
      height: 125px;
      width: 90%;
      border-top: 1px $grey-outline solid;
      padding: 32px;
      gap: 16px;

      span {
        border-radius: 5px;
        padding: 15px 32px;
        cursor: pointer;
        height: max-content;
        border: 1px $grey-outline solid;
        font-weight: 500;
        line-height: 20px;
      }
      #btnCancel {
        color: $body-content-modal-color;
        background-color: #fafafa;
      }
      #btnSave {
        color: $body-bar-modal-color;
        background-color: $body-bar-modal-background-color;
      }
    }
    .modalHeader {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 32px;
      h2 {
        color: $body-bar-modal-color;
        font-weight: 400 !important;
        font-size: 36px;
        margin-bottom: 0;
      }
      & > span {
        align-self: center;
        & > span::before {
          font-size: 25px;
        }
      }
      color: $body-bar-modal-color;
      background-color: $body-bar-modal-background-color;
    }

    .modalBodyScroll {
      overflow-y: auto;
      max-height: 500px;
      direction: rtl;
      & > div {
        direction: ltr;
      }
    }
  }
}

@media (max-width: 1024px) {  
.modalBox{
    min-width: 95%;
}
.closeIcon{
  opacity: 1;
  font-size: 15px;
  margin-right: 10px;
}
}