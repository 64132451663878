.title{
    color:var(--primaryColor);
  }
  .introductionDescription{
    color:var(--textColor); 
    padding: 5px 10px;
    border-radius: 5px;
    text-align: justify;
    
  }

  // .wrapper{
  //   max-width: 900px;
  // }
  .innerWrapper{
    display: flex;
    align-items: center; 
  }