 
@import "../../scss/colors.scss";
@import "../../scss/structure.scss";
.saveBtn{
    background: var(--secondaryColor);
    width: 60px !important;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 0;
    color:var( --textColor);
    font-size: 25px;
    cursor: pointer;
}
.addBtn{
    width: 24px;
    text-align: center; 
    color: var( --textColor); 
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center; 
    border-radius: 5px; 
    width: 36px;
    text-align: center; 
        color:var(--primaryColor);
    padding: 5px 17px;
    display: flex;
    justify-content: center; 
    background: var(--secondaryColor);
    border: 1px var(--backgroundDarkerColor) solid;
    box-shadow: $boxShadow;
    color:var(--textColor);
    transition: 0.2s background-color, 0.5s transform;
}
.addBtn:hover{
    transition: 0.2s background-color, 0.5s transform;
    transform: scale(1.05);
    background: var(--primaryColor);
}
.backgroundModal{
    background: var(--backgroundColor);
}
.inputModal{
    background: transparent;
    border: 2px var(--secondaryColor) solid !important;
    color: var( --textColor);
    padding: 5px;
    height: 42px;
}
.inputModal>option{
    background: var(--backgroundColor);
    border: 2px var(--secondaryColor) solid !important;
    color: var( --textColor);
    padding: 15px;
}
@media (max-width: 1024px) {  
    .addBtn{
        padding:  5px;
    } 
     
   .inputModal{
        height: 23px;
        padding: 3px;
    }
    .saveBtn{ 
        width: 30px !important; 
        padding: 5px 10px;
        font-size: 15px;
    }
}