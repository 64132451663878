@import '../../common/scss/structure.scss';
.foodItem{
    background: var(--secondaryColor);
    padding: 10px 20px;
    border-radius: $borderRadius;
    flex: 1 1 calc(30%); 
    box-sizing: border-box;   
    border-radius: 4px;
}
.mealsWrapper{
    width: 100%;
    display: flex;
    max-width: 400px;

    flex-wrap: wrap;  
    gap: 16px;
 
}
.buttonFood{
    min-width: 125px;
    background: var(--secondaryColor);
    padding: 10px;
    border-radius: $borderRadius;
    text-align: center; 
}