.logo{
    background-size: contain;
    background-repeat: no-repeat;
}
.mainLogo{
    background-image: url(../../../../static/img/logo.png);
    background-repeat: no-repeat;
    background-position: center;
} 
.mAvatar{
    background-image: url(../../../../static/img/m_avatar.svg);
}
.fAvatar{
    background-image: url(../../../../static/img/f_avatar.svg);
}
.mainLogoWithoutText{
    background-image: url(../../../../static/img/mainLogoWithoutText.svg);
} 
.facebookLogo{
    background-image: url(../../../../static/img/Facebook.svg);;
}
.googleLogo{
    background-image: url(../../../../static/img/Google.svg);
}
.instagramLogo{
    background-image: url(../../../../static/img/Instagram.svg);
}
.linkedinLogo{
    background-image: url(../../../../static/img/Linkedin.svg);
}