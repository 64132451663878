.leftProgress{
    position: absolute;
                left: 55px;
                width: 100px;
                transform: translate(0%, 50%) rotateZ(-30deg);
}
.centerProgress{
    position: absolute;
    width: 100px;
    left: 50%;
    transform: translate(-50%, 0%)
}
.rightProgress{
    position: absolute;
    right: 50px;
    transform: translate(0%, 50%) rotateZ(30deg);
}
@media (max-width: 1024px) {  
    .leftProgress{
        position: absolute;
                    left: 0;
                    width: 30px;
                    transform: translate(0%, 50%) rotateZ(-30deg);
    }
    .centerProgress{
        position: absolute;
        width: 100px;
        left: 50%;
        transform: translate(-50%, 0%)
    }
    .rightProgress{
        position: absolute;
        right: 0px;
        transform: translate(35%, 50%) rotateZ(30deg);
    }
  }