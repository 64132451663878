.foodName{


}
.foodDescription{
    position: absolute;
    display: none;
}
.foodName:hover>.foodDescription{
    display: block;
}
.foodUpperIndex{
 position: absolute;
 font-size: 12px;;
 top: 0;   
}
.foodMacronutrientsValue{
    position: relative;
}