@import "../../../common/scss/colors.scss";
@import "../../../common/scss/structure.scss";
.totalContainer{
    background: var(--backgroundColor);
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.totalContainerWrapper{
    padding:  10px;
    background: var(--backgroundDarkerColor);
    border-radius: $borderRadius;
    margin: 10px;
}
.wrapperModal{ 
    height: 100vh;
}