@import "/src/components/common/scss/colors.scss";
@import "/src/components/common/scss/structure.scss";
.transparentInputGrey{
    color:var( --textColor);
    background: var(--backgroundDarkerColor);
    border: 1px rgba(0,0,0,0.1) solid;
}
.buttonPrimary{
    cursor: pointer;
    background: var(--secondaryColor);
    color: var( --textColor);
    border-radius: $borderRadius;
    padding:10px 20px;
    text-align: center;
}
.historicWrapper{
    color: var(--secondaryColor)  ;
    background: transparent;
    border:5px  var(--secondaryColor) solid;
    width: 40px;
    border-radius: 100px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: fit-content;
    margin-left: auto;
    transform: rotate(270deg);
}
 
.historicWrapper:hover{
    color: var( --textColor);
    background: var(--secondaryColor) !important;
    transition: 0.5s ease-in-out color,  0.5s ease-in-out background-color; 
    cursor: pointer; 
}