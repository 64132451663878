.buttonMove{
    width: 100px;
    background: var(--primaryColor);
    color: var(--textColor);
    padding: 5px 10px;
    border-radius: 5px;
    margin: 10px 20px 20px auto;
    position: absolute; 
}
.buttonMoveRight{
    right: 0;
    @extend .buttonMove
}
.buttonMoveLeft{
    left: 40px;
    @extend .buttonMove
}