@import "../../../common/scss/colors.scss";
@import "../../../common/scss/structure.scss";
.portion{
  background: var(--secondaryColor);
  border:1px var(--secondaryColor) solid;
  padding: 5px 20px;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  border-radius: $borderRadius;
}
.portionsWrapper{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}