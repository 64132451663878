@import "/src/components/common/scss/colors.scss";
@import "/src/components/common/scss/structure.scss";

.authWrapper {
    display: grid;
    place-items: center;
    height: 100vh;
}
@font-face {
  font-family: 'Outfit';
  src: url('../static/font/Outfit-Regular.ttf') format('truetype');
  /* Add other font formats (eot, woff, woff2) as needed */
}

@font-face {
  font-family: 'Outfit';
  font-weight: bold;
  src: url('../static/font/Outfit-Bold.ttf') format('truetype');
  /* Add other font formats (eot, woff, woff2) as needed */
}

@import "/src//components/common/scss/colors.scss";
@import "/src//components/common/scss/structure.scss"; 
.authWrapper {
  background: url("../../static/img/background-image.jpg") no-repeat center center;
  background-size: cover;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  position: relative; 
}

.authWrapper::before { 
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a070c;  
  z-index: 1;  
  opacity: 0.8;
}
.authWrapperInnerInput{
  background-color: transparent; 
  margin: 0;
}

.authInnerBlock{
  
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    z-index: 2;
}

.root{

}
 
div.auth{
  position: relative;
  height: fit-content;

}
label.auth{
  color: #AAAFAC;
 position: absolute;
 left: 25px;
 top: 5px;
 font-size: 13px; 
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
}
 
.buttonAuthFull{
 width: 100%;
}
.authHeader{
  padding-top: 60px;
;
  position: relative; 
  content: '';
}
// .authHeader::before{
//   height: 40px; 
// ;
//   position: absolute;
//   content: "";
//   margin-top: -50px;
//   width: 100%;
//   border-radius: 20px 20px 0px 0px; 
// }
.authFooter {
  width: 100%;

  padding-bottom: 40px;
  position: relative;  
  border-radius: 0;
}

// .authFooter::after {
//   content: "";
//   display: block;
//   position: absolute; 
//   left: 0;
//   width: 100%;
//   height: 40px;  
//   margin-top: 40px;

//   z-index: 1;
//   border-radius: 0px 0px 20px 20px; 
// }
.authSubmit{
  background: transparent; 
  border-radius: 8px;
  text-align: center; 
  cursor: pointer; 
  margin-bottom: 0px;
  padding: 15px;
  color: var(--textColor);
 
  font-size: 2vmin;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
}
span{
  color: var(--textSecondaryColor);

text-align: right;
font-family: Outfit;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
a{ 
font-family: Outfit;
text-decoration:  none !important;
font-size: 20px;

font-style: normal;
font-weight: 400;
line-height: normal;
color:var(--primaryColor) !important;
}

.auth{
  margin: 0;
}
.wrapperInput{
  position: relative; padding: 0px; margin: 0px;
  padding: 10px 20px;
  margin: 0px 20px;
  }
input.auth, select.auth{
  font-size: 20px;   
  margin: 20px;
   
  //padding: 10px 0 5px 5px;
  border: var(--primaryColor) solid;  
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
  padding: 5px;
  color:var( --textColor); 
}
@media (max-width: 1048px) {
  input.auth{
    height: 20px;
  }
  label.auth{
  font-size: 8px;
  }
  select.auth{
     height: 70px;
  }
}
@media (max-width: 768px) {
  span{
    font-size: 10px;
    
  }
  .authSubmit{
    font-size: 10px;

  }
  input.auth{
     font-size: 10px;
     
  }

  select.auth{
    
    font-size: 10px;
  }
  a{
    font-size: 10px;

  }
}
.authInnerBlock {  
    display: flex;
    justify-content: center;
    flex-direction: column; 
    align-items: center;
    align-content: center;
    position: relative; 
    box-shadow: $boxShadow;
    border: 1px  color-mix(in srgb, var(--primaryColor) 20%, transparent) solid;
    background-color: color-mix(in srgb, var(--backgroundDarkerColor) 60%, transparent); 
}
input.auth::placeholder {
  font-weight: bold;
  opacity: 0.5;
  color:  var(--textSecondaryColor);
}
.authWrapperInner{
  width: 100%;
  justify-content: center;
  display: flex;
  padding: 20px 10px; 
}
div.auth{
  margin-left: 0;
  margin-right: 0;
  padding-left: 30px;
  padding-right: 30px;
  background: transparent;
   
}
.auth>label{ 
  padding: 0px;
  text-transform: capitalize;
  color: var(--textSecondaryColor)
}
.auth-logo{
  border: 1px black solid;
    border-radius: 41px;
    padding: 10px;
    box-shadow: inset 2px 2px 6px 0px rgba(0, 0, 0, 0.7);
}
.buttonAuthFull{
  width: 100%;
 }

.authErrorMessage{

  padding-left: 20px;
  color: $warningColor;
  text-transform: capitalize;
}

.cutoutWrapper {
    position: relative;
    width: 160px;
    height: 60px;
    margin: 0 auto 20px;
  }
 
.cutout {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    background: inherit; /* Inherit the grandparent's background */
    background-clip: padding-box; /* Clip the background to the padding box */
    transform: translate(-50%, -50%); /* Center the cutout */
    clip-path: circle(50%); /* Create a circular cutout */
    pointer-events: none; /* Allow interactions to pass through the cutout */
    z-index: 1; /* Ensure it is above the child content */
  }
 