@import '../../common/scss/colors.scss';
@import '../../common/scss/structure.scss';


.themeModal{
  min-width: 500px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 10px 20px ;
          margin-top: 30px;
          margin-bottom: 30px;
}
.themeWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  
    gap: 20px;   
    justify-items: stretch;
    grid-template-columns: repeat(3, 100px);
  grid-template-rows: repeat(3, 100px);
  background: var(--backgroundColor);
  padding: 10px 20px;
  gap: 10px;
  border-radius: $borderRadius;
  box-shadow: inset $boxShadow;
  grid-template-areas:
    "a a b"
    "a a b"
    "c d d";
  align-content: space-between;
  justify-content: space-around;
  }
  
  .baseTheme {
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border: 5px solid transparent;
    flex-direction: column;
    width: fit-content;
    box-shadow: $boxShadow;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    }
  
    & > p {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
    }
  }
 
.bloodyTheme{
    color:$bloodyColorDarker;
    background: $bloodyBlackColor;
    &>p{   
        color: $bloodyPrimaryTextColor;
    }
}

.lightTheme {
    color: $lightTextColor;
    background: $lightBackgroundColor;
  
    & > p {
      color: $lightTextColor;
    }
  }
  
  .darkTheme {
    color: $darkTextColor;
    background: $darkBackgroundColor;
  
    & > p {
      color: $darkTextColor;
    }
  }
  
  .forestTheme {
    color: $forestTextColor;
    background: $forestBackgroundColor;
  
    & > p {
      color: $forestTextColor;
    }
  }
  
  .royalTheme {
    color: $royalTextColor;
    background: $royalBackgroundColor;
  
    & > p {
      color: $royalTextColor;
    }
  }
  
  .solarTheme {
    color: $solarTextColor;
    background: $solarBackgroundColor;
  
    & > p {
      color: $solarTextColor;
    }
  }
  
  .oceanTheme {
    color: $oceanTextColor;
    background: $oceanBackgroundColor;
  
    & > p {
      color: $oceanTextColor;
    }
  }
  
@media (max-width: 1024px) {  
  .themeModal{
    min-width: auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 3px 5px ;
          }
          .themeWrapper {
            gap: 3px;
            grid-template-columns: repeat(3, 60px);
            padding: 3px 5px ;
            margin-top: 10px;
            margin-bottom: 10px;
  }
  }