.dropdown {
    @apply absolute top-[42px] right-0 left-0 bg-white border overflow-y-scroll shadow-lg
} 
.dropdownItems{
    max-height: 200px;
    overflow-y: auto;
}
.foodDescription{
    display: none;
    position: absolute;
}
li{
    position: relative;
}
li:hover>.foodDescription{
    display: block;
    color: black;
    background: white;
    padding: 10px;
    border-radius: 15px;
}