@import '../../../common/scss/structure.scss';
.brandName{
    padding: 5px 10px;
    border-radius: 5px; 
    right: 0;
    top: 0;
    color: var(--primaryColor); 
} 
.foodDescription{
    width: 100%;
    display: flex; 
    align-items: center;
    background: var(--backgroundColor);
    padding: 10px 10px; 
    border-radius: $borderRadius;
}
