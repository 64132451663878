.title{
    color:var(--primaryColor);
  }
  .introductionDescription{
    color:var(--textColor); 
    padding: 5px 10px;
    border-radius: 5px;
    text-align: justify;
    margin-right: 50px;
    z-index: 22;
    position: relative; 
background: var(--backgroundDarkerColor);
border: 1px var(--primaryColor) solid;  
}

  // .wrapper{
  //   max-width: 900px;
  // }
  .innerWrapper{
    display: flex;
    align-items: center; 
  }
  .maleLogo{ 
        background: url("../../../../static/img/m_avatar.svg") no-repeat center center;
        background-size: cover;
        height: 200px;
        width: 200px;
        position: relative;   
  }

  .femaleLogo{
    background: url("../../../../static/img/f_avatar.svg") no-repeat center center;
    background-size: cover;
    height: 200px;
    width: 200px;
    position: relative;  
  }
  .baseBodyLogo{
    background-size: cover;
    height: 200px;
    width: 100px;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -70px;
    transform: rotateZ(8deg);


  }
  .maleBodyLogo{
    background: url("../../../../static/img//male_body.png") no-repeat center center;
    @extend .baseBodyLogo;
  }
  .femaleBodyLogo{
    background: url("../../../../static/img/female_body.png") no-repeat center center;
    @extend .baseBodyLogo;
  }