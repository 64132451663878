@import "../../../common/scss/colors.scss";
@import "../../../common/scss/structure.scss";
.userList{
    min-height: 40px;
    padding: 20px;
    background-color: var(--backgroundColor);
    box-shadow: 2px 2px 2px inset;
}
.headerOfGroup{
    width: 100%;
    display: flex;
    justify-content: space-between;  
    flex-wrap: wrap;
}
.groupItem{ 
    border: 2px var(--secondaryColor) solid;
    position: relative;
}
.groupItem.accepted, 
.groupItem.accepted * {
    cursor: pointer;
}
.action{
    
    display: flex;
    justify-content: center;
}
.groupItem.waiting{
    cursor: default; 
   
        color:var( --textColor) !important
  
}
.statusOfInvitation{
    color:var(--primaryColor);
    position: absolute;
    right: 20px;
    bottom: 10px;
}
.groupItem{ 
    color:var( --textColor);
    background: var(--backgroundDarkerColor);
    border-radius: 18px;
    width:fit-content;
    padding: 20px;
    display: block;
    min-width: 250px;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 30px;
}
.userList{
    display: flex;
    width: 100%; 
}
.footerOfCard{
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
}
.logoContainer{
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}
