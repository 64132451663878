@import "../../../common/scss/colors.scss";
@import "../../../common/scss/structure.scss";
.wrapper{
    margin-left: 0; 
    color: var( --textColor);
    margin-top: 20px; 
    border:2px var(--secondaryColor) solid;
    border-radius: $borderRadius;
    padding:10px 20px;
    position: relative;
}