 
@import "../../../../../../src/components/common/scss/colors.scss";
@import "../../../../../../src/components/common/scss/structure.scss";
 
 
.text{
    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    background: var(--backgroundDarkerColor);
    padding: 10px 20px;
    box-shadow: inset 3px 3px 5px black;
    min-width: 200px;
}
.trainingStateWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    justify-content: space-between;
}