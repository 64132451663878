/* sidebar.styles.css */
@import "../../common/scss/colors.scss";
@import "../../common/scss/structure.scss";
/* Styles for components outside of the burger menu */
nav > div > div > div {
    display: flex;
    align-items: center;
  }
  .underConstruction::after {
    content: "Under Construction";
    display: block;
    color: var(--primaryColor);
    font-weight: bold;
    position: absolute;
    top: 0;
    opacity: 0.3;
    text-align: center;
    transform: rotateZ(45deg);
    left: 0;
}
  /* Styles for burger icon */
  .burgerIcon {
    display: none; /* Hidden by default */
    cursor: pointer;
    margin-right: 10px;
  }
  .sidebarContent{
    
    vertical-align:center; 
    align-items:center;
    display:flex; 
    justify-content: space-between;
    width:100%;
  }
  .greyMenuBackground{
    background: var(--backgroundColor);
  }
  .sidebarContent >a{
    color: var(--primaryColor) !important;
    padding: 5px 10px;
    text-transform: uppercase;
  }

  /* Show burger icon for small screens */
  @media (max-width: 768px) {
    .burgerIcon {
      display: block;
      padding: 10px 20px; 
        position: fixed; 
        top: 10px;
        width: 100%;
        z-index: 200; 
        border: 1px var(--backgroundColor) solid;
        background: var(--backgroundDarkerColor);
    }
    .sidebarContent{
      display: none;
      flex-direction: column;
      position: fixed; 
      margin-top: 30px;
    left: 20px;
    opacity: 0;
    border-radius: 10px;
    }
    .sidebarContent.displayFlex{
      transition: 1s;
      opacity: 1; 
             display: list-item;;
      box-shadow: $boxShadowOriginal;
      top: 0;
        bottom: 0;
        width: 100%;
        left: 0;
        margin-top: 0;
        border-radius: 0;
        background: var(--backgroundColor);
        color: var(--textColor);
        text-align: center; 
        z-index: 120;
        padding: 30px;
        padding-top: 80px;
    }
 
    .sidebarContent >a{

      width: 100%;   
       color:var( --textColor) !important;
      border-bottom: 1px var(--primaryColor) solid; 
      padding: 2px 5px;
      display: flex;
      justify-content: center;
      vertical-align: middle;
      text-align: center;
      align-items: center;
      border: 1px var(--primaryColor) solid;
        height: 30px;
        border-radius: 5px;
        margin:20px;
    }
    .sidebarContent >a:hover{
      width: 100%;
      color:var( --textColor) !important;
      transform: scale(1.05);
      background: var(--primaryColor); 
      padding: 2px 5px;
    }
  
   
  }
  