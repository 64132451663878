 
@import "../../../common/scss/colors.scss";
@import "../../../common/scss/structure.scss";
 
.textInformation{
  color:var(--primaryColor);
  margin-right: 10px;
  margin-bottom: 40px;
    color: var(--primaryColor);
    text-align: center;
    text-transform: uppercase;
}
.addIcon{ 
    flex: 1; height: 100%;
    padding: 15px 20px;
    border-radius: $borderRadius $borderRadius 0 0;
    color: var(--primaryColor);
    font-size: 60px;
    justify-content: center;
    display: flex;
    max-width: 135px;
    width: 135px;
    align-items: center;
    vertical-align: middle;
    color: var(--secondaryColor);
    border:5px  var(--secondaryColor) solid;
    background: transparent;
    cursor:pointer;
}
.addIcon:hover{
    color: var( --textColor);
    background: var(--secondaryColor);
    transition: 0.5s ease-in-out color,  0.5s ease-in-out background-color; 
  
}
.mealAdd{
    display: flex;
    width: 100%;
    height: 20px;
    margin: 0 20px;
    width: 100%;
    font-size: 24px;
    padding: 10px 40px;
    border-radius: $borderRadius;
    width: fit-content;
    height: fit-content;
    text-align: center;
    border-radius: 5px;
    margin-right: auto;
    margin-left: auto;
    color: var( --textColor) ; 
    background: var(--secondaryColor); 
    outline: none;
    border: none;
}
.mealAddExist{ 
   
   position: absolute;
   right: 0;
   width: 100px;
   margin-top: -50px;
   top: 0;
    background: var(--secondaryColor);
    width: fit-content;
    color: var(--secondaryColor) ;
    font-size: 15px;
    margin-left: auto;
    display: block;
    /* width: 102px; */
    margin-right: 0;
    box-shadow:  $boxShadow;
    color: var( --textColor);
}
.mealAddExist:hover{
    background:  var(--primaryColor);
  
    transition: 0.2s background ease-in, 
                 0.1s box-shadow ease-in;
}
 
 
.infoWrapper{
 position: relative;
 z-index: 2;
}
.infoContainer{
    position: absolute;
    background: var(--secondaryColor);
    padding: 5px;
    z-index: 2;
    border-radius: 5px;
}
.mealInfoIcon{
    font-size:20px;
    
}
.mealDisplayIcon{
     
    font-size: 60px;
    justify-content: center;
    display: flex;
    max-width: 205px;
    width: 205px;
    align-items: center;
    vertical-align: middle;
}
.mealInfoDetail{
    font-size: 20px;
}
.mealsFood{
    background:var(--backgroundDarkerColor) ;
    padding: 5px;
    color:var( --textColor);
    border-radius: $borderRadius;
    margin-top: 2px;
    margin-bottom: 2px;
}
.mealsWrapper{
    display: flex;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle; 
    border: 0px; ;
    flex-direction: column; 
}
.mealInfoIcon{
    cursor: pointer;
}
.customScrollable{
    padding: 25px;
}
.mealInfoIcon:hover{
    color:var(--primaryColor);
}
.buttonFoodOrdinary{
    position: absolute;
    right: 0;
    /* bottom: 0; */
    color: var( --textColor); 
    bottom: 10px;
    right: 10px;
}

@media (max-width: 1024px) {  
    .mealAddExist{ 
        padding: 10px;
        font-size: 12px;
    }
    .mealAdd{
        font-size: 15px;
        padding: 10px 30px;
    }
}