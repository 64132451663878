.container {
    width: var(--size);
    height: var(--size);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .innerBorder {
    width: calc(var(--size) - 2 * var(--border-thickness));
    height: calc(var(--size) - 2 * var(--border-thickness));
    border: var(--border-thickness) solid white; /* White border */
    border-radius: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 1;
  }
  
  .progressRing {
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background: conic-gradient(
      var(--primaryColor) 0% var(--progress),
      transparent var(--progress) 100%
    );
    mask: radial-gradient(farthest-side, transparent calc(100% - var(--thickness)), black);
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--thickness)), black);
    position: absolute;
    z-index: 2;
  }
  
  .centerText {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    font-size: calc(var(--size) / 5);
    color: var( --textColor);
    position: relative;
    z-index: 3;
  }
  