@import "/src//components/common/scss/colors.scss";
@import "/src//components/common/scss/structure.scss"; 
.App {
  text-align: center;
}
body{
  background-color: var(--backgroundDarkerColor) !important;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.border-custom{
  border-radius: $borderRadius;
  border-style: solid;
  border-width: 1px;
}
.border-custom.border-primary{
  border-color: var(--primaryColor) !important; 
}
.border-custom.border-secondary{
  border-color: $secondaryColor !important; 
}
.border-custom.border-primary-alt{
  border-color: var(--primaryColor)Alt !important; 
}
.li{
  list-style:'none'
}
input{
  color:black;
}
.color-white{
  color:var( --textColor);
}
.color-secondary{
  color:$secondaryColor !important;
}
.color-primary{
  color:var(--primaryColor) !important;
}
.primaryColor{
  color:var(--primaryColor);
}
.background-primary{
  color:var( --textColor);
  background:$backgroundColor;
}
.background-white{
  background: white;
}
.secondary-background{ 
  background:$secondaryColor;
}
.greyMenuBackground{
  background: var(--backgroundColor);
}
.darkModalBackground{
  background: var(--backgroundDarkerColor);
}
ul{
  padding: 0;
}
li{
  padding: 0;
}
.primaryBackground{
  color:var( --textColor);
  background:black;
}
div>
.action-wrapper{
  display: none; 
}
.blockBackgroundWrapperWrapper{
  background: var(--backgroundColor);
  padding: 20px;
  margin: 20px;
  box-shadow:$boxShadow;
}
div:hover>
  .action-wrapper{
    display: flex;
  }

.circle{
  border-radius: 90px;
}
.height-full {
  height: calc(100vh - 2rem - 10px - 50px);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var( --textColor);
}
.background-green{
  background: green;
}
.App-link {
  color: #61dafb;
}
.mobileTabletOnly{
  display: none;  
}
 
@media (max-width: 1024px) {  
  .mobileTabletOnly{
    display: block;  
  } 
}
.desktopOnly{
  display: inline;
}
buttoh:hover{
  transition: 0.2s background-color, 0.5s transform;
  transform: scale(1.05);
}
@media (max-width: 1024px) {  
  .desktopOnly {
    display: none;  
  } 
  p,svg,span, div{
    font-size: 10px;
  }
  
}
html, body {
  touch-action: manipulation;  
  -webkit-text-size-adjust: 100%;  
  text-size-adjust: 100%;  
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
