@import "../../../common/scss/colors.scss";
@import "../../../common/scss/structure.scss";
.restaurantTitle{
    color: var( --textColor);
    border: 2px var(--secondaryColor) solid;
    width: fit-content;
    padding: 10px 20px;
    border-radius: $borderRadius;
}
.buttonFood{
    min-width: 50%;
    background: var(--secondaryColor);
    padding: 10px;
    text-align: center; 
}
