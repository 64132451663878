.stepGraphContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100px;  
  }
  
  .stepCircle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--backgroundColor);
    display: flex
;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: var(--textColor);
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .stepActive .stepCircle {
    background-color:var(--primaryColor);
    transform: scale(1.2); 
  }
  
  .stepLabel {
    margin-top: 8px;
    font-size: 14px;
    color: var(--textColor);
    text-align: center;
  }
  
  .stepLine {
    flex-grow: 1;
    height: 4px;
    background-color: var(--textSecondaryColor);
    position: absolute; 
    width: 85%;
    z-index: -1;
    margin: 0 40px;
    margin-bottom: 25px;
  }
  
  .step:not(:last-child) .stepLine {
    width: calc(100% + 40px);  
    left: 50%;
  }
  
  .stepActive .stepLine {
    background-color: #ff5722;  
  }
  @media (max-width: 944px) {  
    .stepLine {
      width: 82%;
    }
  }
  @media (max-width: 770px) {  
    .stepLine {
      width: 78%;
    }
  }
@media (max-width: 624px) {  
  .stepLine {
    width: 73%;
  }
}
@media (max-width: 524px) {  
  .stepLine {
    width: 63%;
  }
}