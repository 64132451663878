@import "/src/components/common/scss/colors.scss";
@import "/src/components/common/scss/structure.scss";
.sidebarColor{
    color:var(--primaryColor)
}
.sidebarBackgroundColor{
    color:var(--secondaryColor)
}
.primaryFill{
 fill:var(--primaryColor);
}
.fillText{
    fill: var(--textColor);
}
.primaryBackgroundFill{
    fill:var(--secondaryColor);
   }