 
@import "../../../../common/scss/colors.scss";
@import "../../../../common/scss/structure.scss";
.lastSessionWrapper{
 
  padding: 10px 20px;
  color: var( --textColor);
  border-radius: $borderRadius;
  justify-content: center;
}
.lastSessionComponent{
  display: flex; 
  min-width:fit-content; 
  max-width: 200px;
  flex-direction: row;
    flex-wrap: wrap;
   
    vertical-align: middle;
    align-items: center;
}
.lastSessionWrapperItems{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;


}
.lastSessionWrapperHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle; 
}
.button{
  background: var(--primaryColor);
  border-radius: $borderRadius;
  padding:10px 20px;
  margin:5px;
  color:var( --textColor) !important;
}
.textInformation{
  color:var(--primaryColor);
  margin-right: 10px;
}
.trainingInfo{
  min-width: 200px;
  display: flex;
  flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--secondaryColor);
    padding: 5px  10px;
    margin: 5px;
    border-radius: $borderRadius;
}
 