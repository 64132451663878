 div:hover> .training-plan-item-actions{
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    transition: 0.5s;
}
.training-plan-item-actions{
    width: 100%;
    height: 100%;
    display: none;
    background: rgba(0, 0, 0, 0.0);
    justify-content: space-between;
    padding: 15px;
}
